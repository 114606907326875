export const LOADING = "LOADING";
export const GET_PROPS = "GET_PROPS";
export const GET_PROPERTY = "GET_PROPERTY";
export const RESET_PROPERTY = "RESET_PROPERTY";
export const MUESTRA_DESTACADAS = "MUESTRA_DESTACADAS";
export const IS_OPEN_MODAL_PICTURE = "IS_OPEN_MODAL_PICTURE";
export const RESET_PROPS = "RESET_PROPS";
//emp
export const GET_EMPRENDIMIENTOS = "GET_EMPRENDIMIENTOS";
export const GET_EMPRENDIMIENTO = "GET_EMPRENDIMIENTO";
export const RESET_EMPRENDIMIENTO = "RESET_EMPRENDIMIENTO";
